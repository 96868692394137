.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-user-chat-message {
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  gap: 10px;
  background-color: #2F59C6;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #FFFFFF !important;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-time {
  font-size: 0.75rem;
  color: #666;
  margin-top: 4px;
  text-align: right;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2F59C6;
  position: absolute;
  right: -7px;
  top: 9px;
}
