.react-chatbot-kit-chat-container {
    position: relative;
    width: 275px;
}

.react-chatbot-kit-chat-inner-container {
    height: 100%;
    background-color: #F5F7FB;
    border-radius: 3px;
    border-radius: 5px;
}

.react-chatbot-kit-chat-header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #efefef;
    font-family: Arial;
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    color: #514f4f;
    padding: 12.5px;
    font-weight: bold;
}

.react-chatbot-kit-chat-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.react-chatbot-kit-chat-message-container {
    padding: 0 17.5px 10px 17.5px;
    /* Depends on height of the whole chat box, header, and submit component. */
    height: 437px;

    overflow-y: scroll;

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    transition: max-height 0.3s ease-out; /* Smooth transition for height adjustment */

    /* Hide scrollbar for Chrome, Safari, and Opera */

    &::-webkit-scrollbar {
        display: none;
    }
}

.react-chatbot-kit-chat-input {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: auto;
    box-shadow: inherit;
    border-radius: 20px;
    display: block;
    flex-grow: 1;
    padding: 10px;
    height: auto;
    flex-wrap: wrap;
    align-content: center;
    border: 1px solid #858585;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #858585, #858585);
    width: 80%;
    max-height: 60px; /* Maximum height for three lines */
    line-height: 20px; /* Adjust based on your design for vertical centering */
    vertical-align: middle;
    top: 8px;
    left: 16px;
    margin-right: 6px;

    font-family: Source Han Sans CN, serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    color: #2B2B2B;

    overflow-y: scroll;

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    transition: max-height 0.3s ease-out; /* Smooth transition for height adjustment */

    /* Hide scrollbar for Chrome, Safari, and Opera */

    &::-webkit-scrollbar {
        display: none;
    }
}

.react-chatbot-kit-chat-input-form {
    /*width: 100%;*/
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
}

.react-chatbot-kit-chat-input::placeholder {
    color: #585858;
}

.react-chatbot-kit-chat-btn-send {
    width: 46px;
    height: 42px;
    top: 8px;
    left: 308px;
    padding: 4px 12px 6px 12px;
    border: none;
    border-radius: 30px;
    gap: 10px;
    background: #2F59C6;
    color: #fff;
}

.react-chatbot-kit-chat-btn-send:disabled {
    background: #D3D3D3;
}

.react-chatbot-kit-chat-btn-send-icon {
    fill: #fff;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    align-content: center;
    text-align: center;
    vertical-align: middle;
}

.react-chatbot-kit-chat-input-container .copyright {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    display: flex; /* This turns the div into a flex container, aligning its children in a row by default */
    align-items: center; /* This vertically centers the flex items if there's extra space on the cross-axis */
    justify-content: center;
}


/* Remove margin from the last item */
.react-chatbot-kit-chat-input-container .copyright > span:last-child {
    margin-right: 0;
}

.react-chatbot-kit-chat-input-container .powered-by {
    font-family: Source Han Sans CN, serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;
    color: #959595;
}

.powered-by,
.branding {
    margin-right: 7px; /* Adjust the margin as needed */
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.react-chatbot-kit-chat-input-container .branding .legallink-logo {
    width: 11.53px;
    height: 12.5px;
    top: 0.23px;
    left: 0.16px;
    padding-right: 5px;
}

.react-chatbot-kit-chat-input-container .branding .legallink-logo svg {
    /* Ensure the SVG aligns well with the text, adjust as needed */
    vertical-align: super;
}

.react-chatbot-kit-chat-input-container .branding .legallink {
    font-family: Source Sans Pro, serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: -0.01em;
    text-align: left;
}

