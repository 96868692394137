.available-times {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap as needed */
    margin-top: 10px; /* Space from the preceding element */
    padding: 10px;
    /*background-color: #f7f7f7; !* Light background to distinguish this section *!*/
}

.available-times .time-slot {
    margin: 0;
    padding: 6px;
    cursor: pointer;
    text-align: center;
    background-color: #e9ecef; /* Slightly darker background for each time slot */
    border-radius: 6px; /* Rounded corners for each time slot */
    transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.available-times .time-slot:hover {
    background-color: #dee2e6; /* Darken time slot on hover */
}
