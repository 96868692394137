.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 22.5px;
  height: 22.5px;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  width: 284px;
  padding: 6px 12px 6px 12px;
  border-radius: 12px;
  background-color: #FFFFFF;
  color: #2B2B2B !important;
  position: relative;
  margin-left: auto;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.react-chatbot-kit-chat-bot-message-time {
    font-size: 0.75rem; /* Smaller font size for the time label */
    color: #666; /* Dimmed color for less emphasis */
    margin-top: 4px; /* Small space between the message box and the time label */
    text-align: left; /* Align the text to the right */
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #FFFFFF;
  position: absolute;
  left: -7px;
  top: 9px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
