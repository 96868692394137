@font-face {
    font-family: 'Source Han Sans CN';
    src: local('Source Han Sans CN'), url('./react-chatbot-kit/src/assets/fonts/SourceHanSansSC-VF.otf.woff2') format('woff2'),
    url('./react-chatbot-kit/src/assets/fonts/SourceHanSansSC-VF.otf.woff2') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro'), url('./react-chatbot-kit/src/assets/fonts/source-sans-pro/SourceSansPro-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body {
  margin: 0;
  font-family: 'Source Han Sans CN', sans-serif, 'SourceSansPro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-calendar {
  width: 100% !important;
}

.react-chatbot-kit-chat-input-container {
    width: 370px;
    min-height: 94px;
    height: auto;
}

.react-chatbot-kit-chat-container {
    Height: 620px;
    border-radius: 12px;
    background: #F5F7FB;
    box-shadow: 0 10px 20px 0 #2F59C64D;
}
