/* Add this CSS to your stylesheet */
.header-booking-btn {
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #2F59C6;
}

.header-booking-btn:hover {
    color: #194ae8;
}

.header-booking-btn:active {
    color: #89a4f6;
}

.header-toggle-btn {
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: 10px;
    vertical-align: middle;
    cursor: pointer;
}
