/* contactForm.css */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px; /* Increased width for a better layout */
    margin: auto;
    padding: 20px;
    border-radius: 8px; /* Added rounded corners */
    background-color: #ffffff; /* Background color for the form */
}

.contact-form label {
    display: flex;
    flex-direction: column;
    gap: 5px; /* Adjusted gap for clarity */
}

.contact-form input[type="text"],
.contact-form input[type="tel"],
.contact-form input[type="email"],
.contact-form select {
    padding: 10px;
    font-size: 14px; /* Improved font size for readability */
    border-radius: 4px; /* Rounded corners for inputs */
    border: 1px solid #ccc; /* Subtle border */
    outline-color: #007bff; /* Highlight color on focus */
}

.contact-form input[type="text"]:focus,
.contact-form input[type="tel"]:focus,
.contact-form input[type="email"]:focus,
.contact-form select:focus {
    border-color: #007bff; /* Highlight border color on focus */
}

.contact-form .error {
    margin-top: 5px;
    font-size: 14px; /* Smaller font size for error messages */
    color: #d9534f; /* Error message color */
}

.contact-form button {
    padding: 10px 15px;
    font-size: 14px;
    color: #ffffff;
    background-color: #007bff; /* Button background color */
    border: none;
    border-radius: 4px; /* Rounded corners for button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.2s; /* Smooth transition for button */
}

.contact-form button:hover {
    background-color: #0056b3; /* Darker shade on hover for button */
}

.contact-form button:disabled {
    background-color: #cccccc; /* Disabled state background color */
    cursor: not-allowed; /* Not-allowed cursor on disabled state */
}
