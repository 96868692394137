@keyframes pulseAnimation {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1.08);
    }
    100% {
        transform: scale(0.9);
    }
}

.pulse-animation {
    animation: pulseAnimation 2s infinite ease-in-out;
}
